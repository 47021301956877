import React from 'react';
import * as Styled from './slpTmImageListStyle';

const SlpTmImageList = ({ sectionData }) => {
  return (
    <Styled.Body>
      <Styled.InnerBody>
        <Styled.BodyContent>
          {sectionData?.header?.trim() && (
            <Styled.HeaderH1>{sectionData?.header}</Styled.HeaderH1>
          )}
          {sectionData?.listOfImagesWithPath &&
            sectionData.listOfImagesWithPath.map((item, index) => {
              return (
                <Styled.IconDiv key={index}>
                  <Styled.ImageStyle
                    src={item?.image?.file?.url}
                  ></Styled.ImageStyle>
                  <Styled.ParaText>
                    {item?.title?.trim() && item?.title}
                  </Styled.ParaText>
                </Styled.IconDiv>
              );
            })}

          {sectionData?.description?.trim() && (
            <Styled.Description>{sectionData.description}</Styled.Description>
          )}
          <Styled.HorizontalBreak />
        </Styled.BodyContent>
      </Styled.InnerBody>
    </Styled.Body>
  );
};

export default SlpTmImageList;
