import styled from 'styled-components';

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1 !important;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;
export const Body = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const InnerBody = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const HeaderH1 = styled.h1`
  text-transform: uppercase;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 32px;
  line-height: 40px;
  padding-top: 0px;
  font-weight: normal !important;
  color: #6e2b8b;
  padding: 25px 0 0 0;
  margin: 0 0 25px 0;
  @media (max-width: 991px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const IconDiv = styled.div`
  display: inline-block;
  width: 120px;
  text-align: center;
  font-size: 12px;
  vertical-align: top;
  @media (max-width: 991px) {
    display: inline-block;
    width: 60px;
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    vertical-align: top;
  }
`;
export const Description = styled.p`
  color: #fff !important;
  background: #6e2b8b;
  font-size: 14px !important;
  padding: 6px;
  margin-bottom: 60px;
  margin: 0 0 20px 0;
  line-height: 22px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  @media (max-width: 991px) {
    color: #fff !important;
    background: #6e2b8b;
    font-size: 15px !important;
    padding: 15px;
    margin-bottom: 60px;
  }
`;

export const ImageStyle = styled.img`
  height: 63px;
  width: auto;
  border: 0;
  vertical-align: top;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    height: 40px;
    width: auto;
  }
`;

export const ParaText = styled.p``;
